<script>
export default {
  data() {
    return {
      initialLoading: false,
      isApplicationsOpen: false,
      discountTrainings: null,
      discountBundles: null,
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    discounts() {
      return this.$store.state.discounts.discounts;
    },
  },
  mounted() {
    this.storeUUID = this.$store.getters['auth/store'].uuid;
    this.page = 1;
    this.initialLoading = true;
    this.$store.dispatch('discounts/fetch', { storeUUID: this.storeUUID, page: this.page })
      .finally(() => (this.initialLoading = false));
  },
  methods: {
    onPageChange(page) {
      this.$store.dispatch('discounts/fetch', { storeUUID: this.storeUUID, page });
    },

    deleteDiscount(discount) {
      const { storeUUID } = this;

      this.$buefy.dialog.confirm({
        message: 'Attention ! <br> Cette action est définitive.',
        type: 'is-danger',
        focusOn: 'cancel',
        onConfirm: () => {
          const loader = this.$buefy.loading.open({ container: this.$refs.box });
          this.$store.dispatch('discounts/remove', { storeUUID, discount })
            .finally(() => loader.close());
        },
      });
    },
    openApplications(discount) {
      this.isApplicationsOpen = true;
      this.discountTrainings = discount.trainings;
      this.discountBundles = discount.bundles;
    },
    closeApplications() {
      this.isApplicationsOpen = false;
      this.discountTrainings = true;
      this.discountBundles = true;
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Vos codes promo
    </template>
    <template #content>
      <p>
        Voici la liste de tous les codes que vous avez créé.
      </p>
      <p v-if="authStore.capabilities.nb_discount_code < 0" class="box is-warning is-flex">
        <b-icon class="mr-3" icon="gem" />
        <span>
          Pas de limitations sur le nombre de codes promo grâce à votre
          <span v-t="`packs.${authStore.plan}`" />
        </span>
      </p>
    </template>

    <template #default>
      <div class="column is-8 is-relative">
        <b-skeleton v-if="initialLoading" height="300" />
        <div v-else ref="box">
          <p v-if="discounts && discounts.data.length < 1" class="box">
            Vous n'avez pas encore créé de code promo.
          </p>
          <template v-else-if="discounts && discounts.data.length > 0">
            <div
              v-for="discount in discounts.data"
              :key="discount.id"
              class="box is-flex is-justify-content-space-between">
              <div>
                <h3 class="title is-5 mb-3">
                  Code : {{ discount.code }}
                  (-{{ discount.value }} {{ discount.type === 'PERCENTAGE' ? '%' : '€' }})
                </h3>
                <p>
                  {{ discount.name }}
                </p>
                <p class="mt-1">
                  Utilisé {{ discount.nb_usage }} fois / {{ discount.max_usage || '&infin;' }}
                </p>
                <p class="mt-1">
                  Valide à partir du
                  <strong>
                    <b-tooltip :label="$moment.utc(discount.start || discount.created_at).local().format('DD/MM/YY HH:mm')">
                      {{ (discount.start || discount.created_at) | momentFromUTC | moment('DD/MM/YY') }}
                    </b-tooltip>
                  </strong>
                  <template v-if="discount.end">
                    jusqu'au
                    <strong>
                      <b-tooltip :label="$moment.utc(discount.end).local().format('DD/MM/YY HH:mm')">
                        {{ discount.end | momentFromUTC | moment('DD/MM/YY') }}
                      </b-tooltip>
                    </strong>
                  </template>
                </p>
                <p v-if="discount.trainings || discount.bundles" class="tag is-outlined mt-2">
                  <b-tooltip position="is-right" multilined>
                    <template #content>
                      Cliquez pour voir le détail
                    </template>
                    <a
                      href="#discount_applications"
                      class="has-text-black"
                      @click.prevent="openApplications(discount)">
                      S'applique à
                      <span v-if="discount.trainings">
                        {{ discount.trainings.length }}
                        formation{{ discount.trainings.length > 1 ? 's' : '' }}
                      </span>
                      <span v-if="discount.trainings && discount.bundles">
                        et à
                      </span>
                      <span v-if="discount.bundles">
                        {{ discount.bundles.length }}
                        pack{{ discount.bundles.length > 1 ? 's' : '' }}
                      </span>
                    </a>
                  </b-tooltip>
                </p>
                <p v-else class="tag is-outlined mt-2">
                  S'applique à tous vos produits
                </p>
              </div>
              <div>
                <b-dropdown position="is-bottom-left" aria-role="menu">
                  <template #trigger>
                    <b-button type="is-text" icon-left="ellipsis-h" />
                  </template>
                  <b-dropdown-item aria-role="menuitem" @click="deleteDiscount(discount)">
                    <span class="has-text-danger">Supprimer le code</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div>
              <b-pagination
                v-if="discounts.meta.total > discounts.meta.per_page"
                :current="discounts.meta.current_page"
                :total="discounts.meta.total"
                :per-page="discounts.meta.per_page"
                @change="onPageChange"
              />
            </div>
          </template>
          <div v-else>
            <b-skeleton height="32" animated />
            <b-skeleton height="32" animated />
            <b-skeleton height="32" animated />
            <b-skeleton height="32" animated />
          </div>
        </div>
      </div>

      <b-modal width="460px" :active="isApplicationsOpen" @close="closeApplications">
        <ul id="discount_applications" class="panel has-background-white">
          <li class="panel-heading">
            Liste des produits
          </li>
          <li v-for="one in discountTrainings" :key="one.uuid" class="panel-block">
            <router-link class="color-inherit" :to="{name: 'training', params: {uuid: one.uuid}}">
              Formation "<b>{{ one.name }}</b>"
            </router-link>
          </li>
          <li v-for="one in discountBundles" :key="one.uuid" class="panel-block">
            <router-link class="color-inherit" :to="{name: 'bundle', params: {uuid: one.uuid}}">
              Pack "<b>{{ one.name }}</b>"
            </router-link>
          </li>
        </ul>
      </b-modal>
    </template>
  </AppFeatureWrapper>
</template>
